const termsOfService = (
  <>
    <h1>Terms and Conditions</h1>
    <p>Last updated: October 24, 2024</p>
    <p>Please read these terms and conditions carefully before using Our Service.</p>

    <h2>Interpretation and Definitions</h2>
    <h3>Interpretation</h3>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
       The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>

    <h3>Definitions</h3>
    <p>For the purposes of these Terms and Conditions:</p>
    <ul>
      <li>
        <p><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party,
           where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election
           of directors or other managing authority.</p>
      </li>
      <li><p><strong>Country</strong> refers to: Portugal</p></li>
      <li>
        <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to PharmaLeap.</p>
      </li>
      <li><p><strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or digital tablet.</p></li>
      <li><p><strong>Service</strong> refers to the Website.</p></li>
      <li>
        <p><strong>Terms and Conditions</strong> (also referred to as "Terms") mean these Terms and Conditions that form the entire agreement
           between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the
           <a href="https://www.termsfeed.com/terms-conditions-generator/" target="_blank" rel="noopener noreferrer">Terms and Conditions Generator</a>.
        </p>
      </li>
      <li>
        <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products, or services)
           provided by a third party that may be displayed, included, or made available by the Service.</p>
      </li>
      <li>
        <p><strong>Website</strong> refers to PharmaLeap, accessible from
          <a href="https://pharmaleap-service-260493862566.europe-southwest1.run.app" target="_blank" rel="noopener noreferrer">
            https://pharmaleap-service-260493862566.europe-southwest1.run.app</a>
        </p>
      </li>
      <li><p><strong>You</strong> means the individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
    </ul>

    <h2>Acknowledgment</h2>
    <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company.
       These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
    <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
       These Terms and Conditions apply to all visitors, users, and others who access or use the Service.</p>
    <p>By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions,
       then You may not access the Service.</p>
    <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
    <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company.
       Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website,
       and it tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

    <h2>Links to Other Websites</h2>
    <p>Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.</p>
    <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
       You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
       by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
    <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services that You visit.</p>

    <h2>Termination</h2>
    <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
    <p>Upon termination, Your right to use the Service will cease immediately.</p>

    <h2>Limitation of Liability</h2>
    <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms
       and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
    <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental,
       indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data, or other information,
       for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
       third-party software, and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms),
       even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
    <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages,
       which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

    <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
    <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.
       To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers,
       expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service,
       including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of course of dealing,
       course of performance, usage, or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind
       that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption,
       meet any performance or reliability standards or be error-free, or that any errors or defects can or will be corrected.</p>

    <h2>Governing Law</h2>
    <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
    <ul>
      <li>By email: <a href="mailto:advanced.pharma.manag@gmail.com">advanced.pharma.manag@gmail.com</a></li>
    </ul>
  </>
);

export default termsOfService;