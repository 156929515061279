import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import linkedinIcon from '../assets/images/linkedin-icon.png';
import Modal from './Modal';
import termsOfService from '../termsOfService'; // Import the Terms of Service content
import privacyPolicyContent from '../privacyPolicy'; // Import the Privacy Policy content

function Footer() {
  const { t } = useTranslation();

  // State to manage modal open status and content
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Open modal with specific content
  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  // Close modal and reset content
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  // Destructure footer translation keys for readability
  const { termsOfService: termsOfServiceText, linkedinAlt } = t('footer', { returnObjects: true });

  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Footer Text */}
        <p className="footer-text">&copy; 2024 PharmaLeap. {t('footer.copyright')}</p>

        {/* Footer Links */}
        <div className="footer-links">
          <button className="footer-link" onClick={() => openModal('privacyPolicy')}>
            {t('footer.privacyPolicy')}
          </button>
          <button className="footer-link" onClick={() => openModal('termsOfService')}>
            {termsOfServiceText}
          </button>
        </div>

        {/* Social Media Links */}
        <div className="social-media">
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt={linkedinAlt} className="social-icon" />
          </a>
        </div>
      </div>

      {/* Modal for Privacy Policy and Terms of Service */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} title={modalContent === 'privacyPolicy' ? t('footer.privacyPolicy') : t('footer.termsOfService')}>
        {modalContent === 'privacyPolicy' && (
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {privacyPolicyContent}
          </div>
        )}
        {modalContent === 'termsOfService' && (
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {termsOfService}
          </div>
        )}
      </Modal>
    </footer>
  );
}

export default Footer;