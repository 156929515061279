import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactSection.css';

// Utility function to get CSRF token from cookies
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
};

const csrfToken = getCookie('csrftoken');

function ContactSection() {
  const { t } = useTranslation();

  // Form state
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      try {
        const response = await fetch('/api/contact/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,  // Include the CSRF token here
            },
            body: JSON.stringify({
                name: formState.name,
                email: formState.email,
                message: formState.message,
            }),
        });

        if (response.ok) {
          setFormStatus(t('contactSection.formStatus.success'));
          resetForm();
        } else {
          setFormStatus(t('contactSection.formStatus.error'));
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        setFormStatus(t('contactSection.formStatus.error'));
      }
    } else {
      setFormStatus(t('contactSection.formStatus.error'));
    }
  };

  // Form validation helper function
  const isFormValid = () => {
    return formState.name && validateEmail(formState.email) && formState.message;
  };

  // Email format validation
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form after successful submission
  const resetForm = () => {
    setFormState({ name: '', email: '', message: '' });
  };

  return (
    <section className="contact-section" id="contact">
      <div className="container">
        <h2 className="section-title">{t('contactSection.title')}</h2>
        <div className="contact-content">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              className="form-input"
              placeholder={t('contactSection.form.namePlaceholder')}
              required
            />
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              className="form-input"
              placeholder={t('contactSection.form.emailPlaceholder')}
              required
            />
            <textarea
              name="message"
              value={formState.message}
              onChange={handleChange}
              className="form-input"
              placeholder={t('contactSection.form.messagePlaceholder')}
              required
            ></textarea>
            <button type="submit" className="btn btn-primary">{t('contactSection.form.buttonText')}</button>
            {formStatus && <p className="form-status">{formStatus}</p>}
          </form>
          <div className="contact-info">
            <p><strong>{t('contactSection.info.email')}:</strong> info@gopharmaleap.com</p>
            <p><strong>{t('contactSection.info.address')}:</strong> Lisbon, Portugal</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;