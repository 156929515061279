// components/WhoWeAreSection.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import './WhoWeAreSection.css';

function WhoWeAreSection() {
  const { t } = useTranslation();

  // Destructure the content from the translation object
  const { title, description, mission, vision } = t('whoWeAreSection', { returnObjects: true });

  return (
    <section className="who-we-are-section" id="who-we-are">
      <div className="container">
        <h2 className="section-title">{title}</h2>
        <p className="description">{description}</p>

        <div className="core-values">
          <div className="value">
            <div className="icon">
              <i className="fas fa-bullseye"></i>
            </div>
            <h3>{t('whoWeAreSection.missionTitle')}</h3>
            <p>{mission}</p>
          </div>
          <div className="value">
            <div className="icon">
              <i className="fas fa-eye"></i>
            </div>
            <h3>{t('whoWeAreSection.visionTitle')}</h3>
            <p>{vision}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoWeAreSection;