import React from 'react';
import { useTranslation } from 'react-i18next';
import './HeroSection.css';

function HeroSection() {
  const { t } = useTranslation(); 

  // Destructure translation keys for cleaner JSX
  const { title, subTitle, subHeading, catchphrase, comingSoon } = t('heroSection', { returnObjects: true });

  return (
    <section className="hero-section" id="hero">
      <div className="hero-image"></div> {/* Placeholder for hero image */}

      <div className="hero-content">
        <h1>{title}</h1>
        <p className="sub-heading">{subTitle}</p>
        <p className="sub-heading">{subHeading}</p>
        <p className="hero-catchphrase"><strong>{catchphrase}</strong></p>
        
        <div className="coming-soon">
          <p>{comingSoon}</p>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;