import React from 'react';
import { useTranslation } from 'react-i18next';
import './FeaturesSection.css';

function FeaturesSection() {
  const { t } = useTranslation();

  // Destructure the features data from the translation object
  const { title, feature1, feature2, feature3, feature4 } = t('featuresSection', { returnObjects: true });
  
  // Create an array for the features to iterate over
  const features = [feature1, feature2, feature3, feature4];

  return (
    <section className="features-section" id="features">
      <h2 className="section-title">{title}</h2>
      <div className="features-container">
        {features.map((feature, index) => (
          <article className="feature" key={index}>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </article>
        ))}
      </div>
    </section>
  );
}

export default FeaturesSection;