import React from 'react';
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './HowItWorksSection.css';

function HowItWorksSection() {
  const { t } = useTranslation();

  // Destructure the title and steps from translation object
  const { title, step1, step2, step3, step4 } = t('howItWorksSection', { returnObjects: true });

  // Store the steps in an array, but only include those that exist
  const steps = [step1, step2, step3, step4].filter(Boolean); // Remove undefined or missing steps

  return (
    <section className="how-it-works-section" id="how-it-works">
      <div className="container">
        <h2 className="section-title">{title}</h2>
        <div className="steps-container">
          {steps.map((step, index) => (
            <article className="step" key={index}>
              <div className="step-icon">
                <i className={step.iconClass}></i>
              </div>
              <div className="step-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;