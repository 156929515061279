const privacyPolicy = (
    <>
      <h1>Privacy Policy</h1>
      <p>Last updated: October 24, 2024</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your
        information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection
        and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help
        of the <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank" rel="noopener noreferrer">Privacy Policy Generator</a>.
      </p>

      <h2>Interpretation and Definitions</h2>

      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>

      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p>
            <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a
            party, where "control" means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers
            to PharmaLeap.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies</strong> are small files that are placed on Your computer, mobile device, or any other device
            by a website, containing the details of Your browsing history on that website among its many uses.
          </p>
        </li>
        <li>
          <p>
            <strong>Country</strong> refers to: Portugal
          </p>
        </li>
        <li>
          <p>
            <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a
            digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong> refers to the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the
            Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service,
            to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the
            Company in analyzing how the Service is used.
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service
            or from the Service infrastructure itself (for example, the duration of a page visit).
          </p>
        </li>
        <li>
          <p>
            <strong>Website</strong> refers to PharmaLeap, accessible from <a href="https://pharmaleap-service-260493862566.europe-southwest1.run.app" target="_blank" rel="noopener noreferrer">https://pharmaleap-service-260493862566.europe-southwest1.run.app</a>
          </p>
        </li>
        <li>
          <p>
            <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>

      <h2>Collecting and Using Your Personal Data</h2>
      <h3>Types of Data Collected</h3>

      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be
        used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>First name and last name</p>
        </li>
        <li>
          <p>Usage Data</p>
        </li>
      </ul>

      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser
        type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on
        those pages, unique device identifiers, and other diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may collect certain information automatically,
        including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of
        Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device
        identifiers, and other diagnostic data.
      </p>
      <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

      <h4>Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
        information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </p>
      <ul>
        <li>
          <p>
            <strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device. You can instruct
            Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept
            Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting
            so that it will refuse Cookies, our Service may use Cookies.
          </p>
        </li>
        <li>
          <p>
            <strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic
            files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or opened an email and for other related
            website statistics (for example, recording the popularity of a certain section and verifying system and server
            integrity).
          </p>
        </li>
      </ul>

      <p>
        Cookies can be <strong>Persistent</strong> or <strong>Session</strong> Cookies. Persistent Cookies remain on Your
        personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close
        Your web browser. You can learn more about cookies on the <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank" rel="noopener noreferrer">TermsFeed website</a>.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>By email: advanced.pharma.manag@gmail.com</li>
      </ul>
    </>
  );

  export default privacyPolicy;