import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import WhoWeAreSection from './components/WhoWeAreSection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import './App.css';

function LandingPage() {
  return (
    <div className="App">
      <Header />

      <main>
        <HeroSection />
        <WhoWeAreSection />
        <FeaturesSection />
        <HowItWorksSection />
        {/* <PricingSection /> */}
        <ContactSection />
      </main>

      <Footer />
    </div>
  );
}

export default LandingPage;