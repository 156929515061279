import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// i18n (internationalization) setup
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files for English and Portuguese
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

// Initialize i18next configuration
i18next
  .use(LanguageDetector)   // Automatically detects user language preference (e.g., via browser or localStorage)
  .use(initReactI18next)   // Enables i18next in React components
  .init({
    resources: {
      en: { translation: translationEN },   // English translation file
      pt: { translation: translationPT },   // Portuguese translation file
    },
    fallbackLng: 'en',   // Default language if the detected one is unavailable
    interpolation: {
      escapeValue: false, // Disable escaping as React already does it by default
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],  // Order of language detection methods
      caches: ['localStorage', 'cookie'],  // Where the language preference is stored for future use
    },
  });

// Create the root of the React DOM
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component wrapped with I18nextProvider to enable translation features
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// Optional: Measure performance in your app. You can log results or send them to an analytics endpoint.
reportWebVitals();