// Modal.js
import React from 'react';
import './Modal.css';

function Modal({ isOpen, closeModal, title, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <div className="modal-body">
          {children}  {/* This content will be in black text */}
        </div>
        <button onClick={closeModal} className="modal-close-btn">Close</button>
      </div>
    </div>
  );
}

export default Modal;