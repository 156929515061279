import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo_no_background.png';
import './Header.css'; // Custom CSS

/**
 * Smooth scroll utility function for smooth scrolling behavior
 * @param {HTMLElement} targetElement - Element to scroll to
 * @param {number} duration - Scroll duration in milliseconds
 */
const smoothScroll = (targetElement, duration = 1000) => {
  const startPosition = window.pageYOffset;
  const targetPosition = targetElement.getBoundingClientRect().top - 70; // Adjust for header height
  const startTime = performance.now();

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollAnimation = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, targetPosition, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(scrollAnimation);
    }
  };

  requestAnimationFrame(scrollAnimation);
};

function Header() {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state for language switcher

  // Handles smooth scrolling for anchor links
  useEffect(() => {
    const handleSmoothScroll = (event) => {
      const anchor = event.target.closest('a[href^="#"]');
      if (anchor) {
        event.preventDefault();
        const targetId = anchor.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          smoothScroll(targetElement);
        }
      }
    };

    document.addEventListener('click', handleSmoothScroll);
    return () => {
      document.removeEventListener('click', handleSmoothScroll);
    };
  }, []);

  // Toggles the language dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Changes the language and closes the dropdown
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false); // Close the dropdown
  };

  return (
    <header className="header">
      {/* Logo */}
      <div className="logo">
        <img src={logo} alt="PharmaLeap Logo" />
      </div>

      {/* Navigation Links */}
      <nav className="nav-center">
        <ul className="nav-links">
          <li><a href="#hero">{t('header.home')}</a></li>
          <li><a href="#who-we-are">{t('header.whoWeAre')}</a></li> {/* Added Who We Are section */}
          <li><a href="#features">{t('header.features')}</a></li>
          <li><a href="#how-it-works">{t('header.howItWorks')}</a></li>
          <li><a href="#contact">{t('header.contact')}</a></li>
        </ul>
      </nav>

      {/* Login Button */}
      <a href="#login" className="btn login-btn">{t('header.login')}</a>

      {/* Language Switcher */}
      <div className={`language-switcher ${isDropdownOpen ? 'open' : ''}`}>
        <button onClick={toggleDropdown} className="language-button">
          <i className="fas fa-globe"></i>
        </button>
        {isDropdownOpen && (
          <ul className="language-dropdown">
            <li onClick={() => changeLanguage('en')} aria-label="English">
              <span role="img" aria-label="English">🇬🇧</span>
            </li>
            <li onClick={() => changeLanguage('pt')} aria-label="Portuguese">
              <span role="img" aria-label="Portuguese">🇵🇹</span>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}

export default Header;